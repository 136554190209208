export default {
    header: {
        self: {},
        items: [],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
                bullet: "dot",
            },
            {
                title: "Users",
                root: true,
                icon: "fas fa-user",
                page: "user",
                bullet: "dot",
            },
            {
                title: "Payment report",
                root: true,
                page: "report",
                icon: "fas fa-chart-pie",
                bullet: "dot",
            },
            {
                title: "Access",
                root: true,
                bullet: "dot",
                icon: "fas fa-user-shield",
                submenu: [
                    {
                        title: "Admins",
                        bullet: "dot",
                        page: "admin",
                    },
                    {
                        title: "Groups",
                        bullet: "dot",
                        page: "group",
                    },
                ],
            },
        ],
    },
};
