let url = "";

if (process.env.NODE_ENV === "development") {
    url = "http://localhost:8000";
} else {
    url = "https://api.notehair.laonlabs.com";
}

export const Constants = {
    url: url,
    baseUrl: url + "/api/admin",
};
