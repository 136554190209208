import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "./dashboard/DashboardPage";
import { LayoutSplashScreen } from "../../../_metronic";

const AdminPage = lazy(() => import("./admin/AdminPage"));
const GroupUserPage = lazy(() => import("./group/GroupUserPage"));
const ReportPage = lazy(() => import("./report/ReportPage"));
const UserPage = lazy(() => import("./user/UserPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/dashboard" />

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/group" component={GroupUserPage} />
                <Route path="/report" component={ReportPage} />
                <Route path="/user" component={UserPage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
