import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from "../../components/BetterBox";
import BetterAlerts from "../../components/BetterAlerts";
import BetterCircularProgress from "../../components/BetterCircularProgress";
import { resetPassword } from "../../crud/auth.crud";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import queryString from "query-string";

class ResetPassword extends Component {
    constructor() {
        super();

        this.state = {
            token: "",
            email: "",
            password: "",
            confirm: "",
            loading: false,
            submitted: false,
        };
    }

    componentDidMount = () => {
        let params = queryString.parse(this.props.location.search);
        this.setState({ token: params.token, email: params.email });
    };

    onSubmit = () => {
        this.setState({ submitted: true });

        if (this.state.password !== this.state.confirm) {
            Swal.fire("Oops!", "The passwords do not match.", "error");
            this.setState({ submitted: false });
            return;
        }

        if (this.state.password.length < 6) {
            Swal.fire("Oops!", "New password must be at least 6 characters", "error");
            this.setState({ submitted: false });
            return;
        }

        resetPassword({ password: this.state.password, password_confirmation: this.state.confirm, token: this.state.token, email: this.state.email })
            .then(async (res) => {
                window.scrollTo(0, 0);

                if (res.data.error) {
                    this.setState({ submitted: false });

                    Swal.fire("Oops!", res.data.error, "error");
                } else {
                    await Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: "Your password has been changed.",
                        showConfirmButton: false,
                        timer: 1500,
                    });

                    window.location.href = "/auth/login";
                }
            })
            .catch((e) => {
                this.setState({ submitted: false });

                Swal.fire("Oops!", "A problem seems to have occurred. Please contact support.", "error");
            });
    };

    render() {
        return (
            <div style={{ margin: "30px 0px 30px 0px" }}>
                <div className="row" style={{ margin: "0" }}>
                    <div className="col-sm-12 col-md-8 offset-md-2 col-lg-5 offset-lg-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <center>
                                    <img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: "250px", height: "auto", marginTop: "30px", marginBottom: "35px" }} />

                                    <p style={{ padding: "10px 50px 15px 50px", fontSize: "18px", color: "#424242", fontWeight: "500" }}>
                                        <b>Hello!</b> Please enter your new password.
                                    </p>
                                </center>
                            </div>

                            <div className="col-sm-12">
                                <BetterCircularProgress loading={this.state.loading}>
                                    <div>
                                        <div className="col-sm-12">
                                            <BetterAlerts errors={this.state.errors} />
                                        </div>

                                        <div>
                                            <div className="col-sm-12">
                                                <BetterBox title="New password">
                                                    <div className="col-sm-12">
                                                        <div className="form-group fg-line">
                                                            <TextField type="password" name="password" label="Password *" margin="normal" variant="outlined" onBlur={(event) => this.setState({ password: event.target.value })} onChange={(event) => this.setState({ password: event.target.value })} />
                                                            <TextField type="password" name="confirm" label="Confirm password *" margin="normal" variant="outlined" onBlur={(event) => this.setState({ confirm: event.target.value })} onChange={(event) => this.setState({ confirm: event.target.value })} />
                                                        </div>
                                                    </div>
                                                </BetterBox>
                                            </div>

                                            <div className="col-sm-12">
                                                <center>
                                                    <br />
                                                    <Button className="btn btn-primary btn-lg btn-bold" onClick={() => this.onSubmit()}>
                                                        <i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Send!
                                                    </Button>

                                                    <br />
                                                    <br />

                                                    <Link to="/auth/login" className="btn btn-label-brand btn-sm btn-bold">
                                                        Back
                                                    </Link>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </BetterCircularProgress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, null)(ResetPassword);
