import axios from "axios";

export const createFormikValidation = (formMap, values) => {
    const errors = {};

    for (let [key, value] of formMap) {
        if (!values[key]) {
            errors[key] = "The field " + value + " is required.";
        }
    }

    return errors;
};

export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

export const formatBRLInput = (input) => {
    input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, "")) / 10 ** 2;

    input.target.value = formatBRL(input.target.value);

    return input;
};

export const formatBRL = (value) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
    });

    value = formatter.format(value);

    return value;
};

export const limparMoeda = (valor) => {
    valor = valor.replace("R$", "");
    valor = valor.replaceAll(".", "");
    valor = valor.replace(",", ".");
    valor = valor.replace("/^p{Z}+|p{Z}+$/u", "");
    valor = valor.trim();

    return valor;
};

export const formatDecimal = (input) => {
    input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, "")) / 10 ** 2;

    const formatter = new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
    });

    input.target.value = formatter.format(input.target.value);

    return input;
};

export const formatHourInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1:$2");

    input.target.value = value;

    return input;
};

export const formatCepInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatCPFInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatDataInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");

    input.target.value = value;

    return input;
};

export const formatIntegerInput = (input) => {
    input.target.value = Number(input.target.value.toString().replace(/[^0-9]/g, ""));

    if (parseInt(input.target.value) === 0) input.target.value = "";

    return input;
};

export const formatTelefoneInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{0})(\d)/, "$1($2");
    value = value.replace(/(\d{2})(\d)/, "$1) $2");
    value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatCNPJInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
    if (input.target.value.length > 8) {
        var cep = input.target.value.replace(/[^0-9]/, "");

        const instance = axios.create();

        instance
            .get(`https://viacep.com.br/ws/${cep}/json`, {})
            .then((res) => {
                if (res.data.erro) {
                    setFieldValue(nomeEndereco, "");
                    setFieldValue(nomeBairro, "");
                    setFieldValue(nomeCidade, "");
                    setFieldValue(nomeUf, "AC");
                } else {
                    setFieldValue(nomeEndereco, res.data.logradouro);
                    setFieldValue(nomeBairro, res.data.bairro);
                    setFieldValue(nomeCidade, res.data.localidade);
                    setFieldValue(nomeUf, res.data.uf);
                }
            })
            .catch((err) => {});
    }

    return formatCepInput(input);
};

export const listaUfs = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];

export const displayValueDecimal = (value) => {
    return value ? value.toLocaleString("pt-BR", { minimumFractionDigits: 2 }).replace(".", ",") : "--";
};
