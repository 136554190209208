import React, { Component } from "react";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { getDashboard } from "../../../crud/dashboard.crud";
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import Swal from "sweetalert2";
import { formatBRL } from "../../../utils/Utils";

export default class DashboardPage extends Component {
    state = {
        loading: false,
        error: false,
        data: null,
    };

    componentDidMount = () => {
        this.setState({ loading: true });

        getDashboard()
            .then((res) => {
                if (res.data.errors) {
                    return Swal.fire("Oops!", res.data.errors ?? "A problem seems to have occurred. Please contact support.", "error");
                }

                this.setState({ ...this.state, data: res.data });
            })
            .catch(() => {
                Swal.fire("Oops!", "A problem seems to have occurred. Please contact support.", "error");

                this.setState({ error: true });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <BetterCircularProgress loading={this.state.loading}>
                {!this.state.error && (
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__number">{this.state.data?.users_count}</span>
                                            <span className="kt-widget26__desc">{`Number of registered users`}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__number">{this.state.data?.free_users_count}</span>
                                            <span className="kt-widget26__desc">{`Number of free users`}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__number">{this.state.data?.paid_users_count}</span>
                                            <span className="kt-widget26__desc">{`Number of paying users`}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__number">{formatBRL(this.state.data?.monthly_value)}</span>
                                            <span className="kt-widget26__desc">{`Total monthly value`}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>
                    </div>
                )}
            </BetterCircularProgress>
        );
    }
}
